import type { Ref, FunctionalComponent } from 'vue';
import { app, appInsights, userSession } from 'o365-modules';
import { $t, logger } from 'o365-utils';
import { ref, onErrorCaptured, h } from 'vue';

export default function useErrorCapture(pOptions: {
    consoleMessagee: string,
    errorRenderFunctionOptions?: {
        type?: 'card' | 'span',
        noRerender?: boolean
        uiMessage?: string
        uiTitleMessage?: string,
    }
}): [Ref<any>, FunctionalComponent] {
    const capturedError = ref(null);
    onErrorCaptured((err: any, _instance: any, _info: string) => {
        if (err?.skipVueHandler) { return; }
        logger.error(`${pOptions.consoleMessagee}\n`, err);
        capturedError.value = err;
        window.requestAnimationFrame(() => {
            const exceptionProperties: Record<string, any> = { app_id: app.id };
            const boundryName = _instance?.$?.type?.__name ?? _instance?.$?.type?.name;
            if (boundryName) {
                exceptionProperties.error_handler = boundryName;
            }
            appInsights.trackException(err, exceptionProperties);
        });
        return false;
    });

    const renderErrorStack = () => {
        const collapseId = `error-collapse-${window.crypto.randomUUID()}`;
        return [
            h('pre', {
                id: collapseId,
                class: 'collapse',
            }, capturedError.value?.stack),
            h('button', {
                class: 'btn btn-sm btn-link text-danger',
                'data-bs-toggle': 'collapse',
                'data-bs-target': `#${collapseId}`
            }, $t('Toggle error stack')),
        ]
    };

    let ErrorRenderer = null
    if (pOptions.errorRenderFunctionOptions) {
        const rerender = () => {
            if (pOptions.errorRenderFunctionOptions?.noRerender) { return; }
            capturedError.value = null;
        };
        switch (pOptions.errorRenderFunctionOptions.type) {
            case 'card':
                ErrorRenderer = () => h('div', { class: 'card text-danger mx-auto my-auto' },
                    h('div', { class: 'card-body' }, [
                        h('h5', { class: 'card-text' }, [
                            h('i', { class: 'bi bi-exclamation-triangle-fill me-1' }),
                            $t('Render Error'),
                        ]),
                        h('p', { class: 'card-text' }, $t($t(pOptions.errorRenderFunctionOptions?.uiMessage ?? 'An error has occurred when rendering this component'))),
                        (userSession.isDeveloper && capturedError.value?.stack) ? renderErrorStack() : undefined,
                        pOptions.errorRenderFunctionOptions?.noRerender
                            ? undefined
                            : h('button', { class: 'btn btn-sm btn-link text-danger', onClick: rerender }, $t('Try to re-render'))
                    ]));
                break;
            default:
                ErrorRenderer = () => h('span', {
                    class: 'text-danger',
                    onClick: rerender,
                    title: $t(pOptions.errorRenderFunctionOptions?.uiTitleMessage ?? 'An error has occurred when rendering this component')
                }, [
                    h('i', { class: 'bi bi-exclamation-triangle-fill me-1' }),
                    $t(pOptions.errorRenderFunctionOptions?.uiMessage ?? 'Render Error')
                ]);
        }
    }


    return [capturedError, ErrorRenderer];
}
